import { CONFIG_OPTION_TOAST_INFO } from "common/toast";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const CopyPreventionComponent = ({ children, isCopy = false }: any) => {
    const { t, i18n } = useTranslation();
    // Prevent default actions like copy, cut, and right-click
    const preventCopyCut = (e: any) => {
        if (!isCopy) {
            e.preventDefault();
            e.stopPropagation();
            toast(`${t('Copying and cutting actions are blocked in this area!')}`, CONFIG_OPTION_TOAST_INFO);
        }
    };

    return (
        <div
            onCopy={preventCopyCut}
            onCut={preventCopyCut}
            style={!!isCopy ? {} : {
                userSelect: "none", // Prevent text selection
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                msUserSelect: "none",
            }}
        >
            {children}
        </div>
    );
};

export default React.memo(CopyPreventionComponent);
