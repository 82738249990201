import { CONFIG_OPTION_TOAST_INFO } from 'common/toast';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface CopyTextToClipboardProps {
    text: string;
    isCopy: boolean;
    children?: any
}

const CopyTextToClipboard: React.FC<CopyTextToClipboardProps> = ({ text = '', isCopy = false, children }) => {
    const { t } = useTranslation();
    const copyTextToClipboard = useCallback(async () => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
        } else {
            document.execCommand('copy', true, text);
        }
        toast(`${t('Copied to the clipboard.')}`, CONFIG_OPTION_TOAST_INFO);
    }, [JSON.stringify(text)]);

    return (
        <div className={`${!!isCopy ? 'border-1 border-dashed border-primary p-2' : ''} position-relative `}>
            {children}
            {!!isCopy && <div className="text-primary align-middle cursor-pointer position-absolute fs-15 px-1 d-flex align-center" style={{ top: 0, right: 16, transform: 'translateY(-50%)', backgroundColor: 'var(--vz-secondary-bg)', fontWeight: 'normal', lineHeight: '16px' }} onClick={copyTextToClipboard}><i className="ri ri-file-copy-line" /> {t('Copy')}</div>}
        </div>
    );
};

export default React.memo(CopyTextToClipboard);