import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import iconDelete from "../../assets/images/gif/trash-bin.gif";

interface ModalConfirmProps {
  size?: string,
  header?: string | React.ReactNode;
  title?: string;
  content?: string | React.ReactNode;
  isShowIcon?: boolean;
  textButtonClose?: string;
  isShowButtonClose?: boolean;
  textButtonConfirm?: string;
  classButtonConfirm?: string;
  classIconButtonConfirm?: string;
  isShowButtonConfirm?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

const ModalConfirm = ({
  size = 'md',
  header,
  title,
  content,
  isShowIcon = true,
  textButtonClose,
  isShowButtonClose = true,
  textButtonConfirm,
  classButtonConfirm = "btn-soft-danger",
  classIconButtonConfirm = "ri-delete-bin-3-line",
  isShowButtonConfirm = true,
  isOpen = false,
  isLoading = false,
  onClose,
  onConfirm,
}: ModalConfirmProps) => {
  const { t } = useTranslation();

  const handleCloseConfirmClick = () => {
    onClose && onClose();
  };

  const handleActionConfirm = () => {
    onConfirm && onConfirm();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        centered={true}
        size={size}
        toggle={handleCloseConfirmClick}
        keyboard={true}
      >
        <ModalHeader toggle={isLoading ? () => { } : handleCloseConfirmClick}>
          {header ?? t("Button Delete Template")}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col sm={12}>
              <div className="row g-3">
                <div className="mt-4 text-center">
                  {isShowIcon && (
                    <img
                      src={iconDelete}
                      alt="delete"
                      style={{ width: "100px" }}
                    />
                  )}
                  <h5 className="mb-3">
                    {title ?? t("Are you sure you want to delete?")}
                  </h5>
                  <p className="text-muted mb-4">
                    {content ??
                      t(
                        "After confirming the delete action, the data will be permanently removed and cannot be recovered. Do you want to proceed with the delete action."
                      )}
                  </p>
                  <div className="hstack gap-2 justify-content-center">
                    {isShowButtonConfirm && (
                      <button
                        className={`btn rounded-pill ${classButtonConfirm} fs-14`}
                        type="button"
                        disabled={isLoading}
                        onClick={handleActionConfirm}
                        style={{ minWidth: '100px' }}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : (
                          <i
                            className={`${classIconButtonConfirm} align-bottom me-1`}
                          ></i>
                        )}
                        {textButtonConfirm ?? t("Button Delete")}
                      </button>
                    )}
                    {isShowButtonClose && (
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={handleCloseConfirmClick}
                        disabled={isLoading}
                        style={{ minWidth: '100px' }}
                      >
                        {textButtonClose ?? t("Button Close")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ModalConfirm;
