import classnames from "classnames";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { getUser, postUser, putUser } from "store/thunks";
import * as Yup from "yup";
import { IRole } from "api/types/_role";
import { IUser } from "api/types/_user";
import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import progileBg from '../../../assets/images/404-error.png';

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    triggerRefresh?: () => void;
    listRole?: IRole[]
}

registerLocale("en", en);
registerLocale("ko", ko);

const UserForm = ({
    id = '',
    triggerRefresh,
    listRole = []
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userPermissions } = useRole();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [initialValuesDefault, setInitialValuesDefault] = useState<IUser | null>(null);

    const [activeTab, setActiveTab] = useState("1");
    const [textExperience, setTextExperience] = useState("You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ");

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const LIST_OPTION_ROLE = listRole?.map((item) => ({ label: item?.name, value: item?.id }));

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                role_id: values?.role?.value ?? LIST_OPTION_ROLE[0]?.value,
                first_name: values?.first_name ?? "",
                last_name: values?.last_name ?? "",
                email: values?.email ?? "",
                is_active: values?.is_active ?? 1,
                ...(id ? {} : { password: values?.password ?? "" })
            };
            const response: any = id ? await putUser(id, data) : await postUser(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${t("First Name is required")}`),
        last_name: Yup.string().required(`${t("Last Name is required")}`),
        email: Yup.string().email(`${t('Please enter a valid email address')}`).required(`${t('Email is required')}`),
        password: Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("Password is required")}`),
        is_active: Yup.number(),
        joining_date: Yup.string().nullable(),
        role: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(), //.required(`${t("Role is required")}`),
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            joining_date: "",
            is_active: 1,
            role: undefined
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vFirstName = valueDefault?.first_name || '';
        const vLastName = valueDefault?.last_name || '';
        const vEmail = valueDefault?.email || '';
        const vPassword = valueDefault?.password || '';
        const vIsActive = valueDefault?.is_active;
        const vJoiningDate = valueDefault?.created_at || '';
        const vRole = LIST_OPTION_ROLE?.filter((item: any) => String(valueDefault?.role_id) === String(item?.value))[0] || LIST_OPTION_ROLE[0];

        setTimeout(() => {
            formik.setFieldValue("first_name", vFirstName);
            formik.setFieldValue("last_name", vLastName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("password", vPassword);
            formik.setFieldValue("is_active", vIsActive);
            formik.setFieldValue("joining_date", vJoiningDate);
            formik.setFieldValue("role", vRole);
        }, 300);
    };

    const handleSubmitChangePassword = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                password: values?.new_password ?? "",
                is_active: initialValuesDefault?.is_active ?? 1,
            };
            const response: any = await putUser(id, data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaChangePassword = Yup.object({
        new_password: Yup.string().min(8, `${t('Password must be at least 8 characters')}`).required(`${t("New Password is required")}`),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password')], `${t("Passwords must match")}`),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: handleSubmitChangePassword,
    });

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getUser(idItem) : {},]);
            if (resDetail?.data) {
                setInitialValuesDefault((_prev) => resDetail?.data);
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img" style={{ height: '100px' }}>
                            <img src={progileBg} className="profile-wid-img" alt="" />
                        </div>
                    </div>
                    <Row>
                        <Col sm={12} className="mt-3">
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}>
                                                {t('Personal Details')}
                                            </NavLink>
                                        </NavItem>
                                        {(id && isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions)) && (<NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                {t('Change Password')}
                                            </NavLink>
                                        </NavItem>)}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <form onSubmit={formik.handleSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">{t('First Name')} <span className="text-danger">*</span></Label>
                                                            <Input
                                                                name="first_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="firstnameInput"
                                                                placeholder={t('Enter your firstname')}
                                                                value={formik?.values?.first_name}
                                                                onChange={(event: any) => formik.setFieldValue('first_name', event?.target?.value)}
                                                            />
                                                            {formik.touched.first_name && formik.errors.first_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.first_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Last Name')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="last_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="lastnameInput"
                                                                placeholder={t('Enter your lastname')}
                                                                value={formik?.values?.last_name}
                                                                onChange={(event: any) => formik.setFieldValue('last_name', event?.target?.value)}
                                                            />
                                                            {formik.touched.last_name && formik.errors.last_name ? (
                                                                <div className="text-danger mt-2">{formik.errors.last_name}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{t('Email Address')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="email"
                                                                type="text"
                                                                className="form-control"
                                                                id="emailInput"
                                                                placeholder={t('Enter your email')}
                                                                value={formik?.values?.email}
                                                                onChange={(event: any) => formik.setFieldValue('email', event?.target?.value)}
                                                            />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger mt-2">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {!id && (<Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="passwordInput" className="form-label">{t('Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="password"
                                                                type="password"
                                                                className="form-control"
                                                                id="passwordInput"
                                                                placeholder={t('Enter Password')}
                                                                value={formik?.values?.password}
                                                                onChange={(event: any) => formik.setFieldValue('password', event?.target?.value)}
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="text-danger mt-2">{formik.errors.password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>)}
                                                    {id && (<Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="JoiningdatInput" className="form-label">{t('Joining Date')}</Label>
                                                            <Input
                                                                name="joining_date"
                                                                className="form-control"
                                                                disabled
                                                                value={formik?.values?.joining_date || ''}
                                                            />
                                                        </div>
                                                    </Col>)}
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Role')} <span className="text-danger"> *</span></Label>
                                                            <Select
                                                                name="role"
                                                                className="mb-0 dropdown-status-rounded"
                                                                classNamePrefix="name-prefix"
                                                                placeholder={t('Role')}
                                                                value={formik?.values?.role || LIST_OPTION_ROLE[0]}
                                                                onChange={(event: any) => formik.setFieldValue('role', event)}
                                                                options={LIST_OPTION_ROLE}
                                                            ></Select>
                                                            {formik.touched.role && formik.errors.role ? (
                                                                <div className="text-danger mt-2">{formik.errors.role}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <h5 className="card-title text-decoration-underline mb-3">{t('Application Account')}:</h5>
                                                            <ul className="list-unstyled mb-0">
                                                                <li className="d-flex">
                                                                    <div className="flex-grow-1">
                                                                        <label htmlFor="directMessage"
                                                                            className="form-check-label fs-14">{t('Setting Active')}</label>
                                                                        <p className="text-muted">{t('Allows the account to log into the system')}</p>
                                                                    </div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="form-check form-switch">
                                                                            <Input
                                                                                name="is_active"
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id="directMessage"
                                                                                checked={!!Number(formik?.values?.is_active)}
                                                                                onChange={(event: any) => formik.setFieldValue('is_active', Number(event?.target.checked))}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            {isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions) && (
                                                                <button
                                                                    style={{ width: '140px' }}
                                                                    type="submit"
                                                                    className="btn btn-primary fs-14 rounded-pill">
                                                                    {isLoading ? <Spinner size="sm me-2" ></Spinner> : <></>}
                                                                    {id ? t('Button Update User') : t('Button Create User')}
                                                                </button>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <form onSubmit={formikChangePassword.handleSubmit}>
                                                <Row className="g-2">
                                                    <Col lg={6}>
                                                        <div>
                                                            <Label htmlFor="newpasswordInput" className="form-label">{t('New Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="new_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="newpasswordInput"
                                                                placeholder={t('New Password')}
                                                                value={formikChangePassword?.values?.new_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('new_password', event?.target?.value)}
                                                            />
                                                            {formikChangePassword.touched.new_password && formikChangePassword.errors.new_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.new_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div>
                                                            <Label htmlFor="confirmpasswordInput" className="form-label">{t('Confirm Password')} <span className="text-danger"> *</span></Label>
                                                            <Input
                                                                name="confirm_password"
                                                                type="password"
                                                                className="form-control"
                                                                id="confirmpasswordInput"
                                                                placeholder={t('Confirm Password')}
                                                                value={formikChangePassword?.values?.confirm_password}
                                                                onChange={(event: any) => formikChangePassword.setFieldValue('confirm_password', event?.target?.value)}
                                                            />
                                                            {formikChangePassword.touched.confirm_password && formikChangePassword.errors.confirm_password ? (
                                                                <div className="text-danger mt-2">{formikChangePassword.errors.confirm_password}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="text-end mt-3">
                                                            {isHavePermissionRole(ROLES_FOR_APP.USER_UPDATE, userPermissions) && (
                                                                <button
                                                                    style={{ width: '180px' }}
                                                                    type="submit"
                                                                    className="btn btn-primary fs-14 rounded-pill"
                                                                >
                                                                    {isLoading && <Spinner size="sm me-2" ></Spinner>}
                                                                    {t('Change Password')}
                                                                </button>)}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </form>
                                        </TabPane>
                                    </TabContent >
                                </CardBody >
                            </Card >
                        </Col >
                    </Row >
                </Container >
            </div>
            <ToastContainer closeButton={false} autoClose={1000} limit={2} />
        </React.Fragment >
    );
};

export default UserForm;
