import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

import HomeLanding from '../pages/Home_Landing'

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile

import Profile from "pages/User/Profile";
import UserProfile from "../pages/Authentication/user-profile";

import RoleList from "pages/User/Role";
import UserList from "pages/User/User";
import AINewsList from "pages/News/AINews";
import MyNewsList from "pages/News/MyNews";
import BoostingNewsList from "pages/News/BoostingNews";


const ROUTES = {
  LOGIN: '/login',
  HOME: '/',
  DASHBOARD: '/dashboard',

  PROFILE: '/profile',
  PROFILE_SETTING: '/pages-profile-settings',

  USER_LIST: '/user/list',
  USER_ROLE: '/user/role',
  USER_PROFILE: '/user/profile',
  MANAGEMENT_PROXY: '/user/proxy',

  AI_NEWS: '/ai-news',
  BOOSTING_NEWS: '/boosting-news',
  MY_NEWS: '/my-news'

}

const authProtectedRoutes = [
  { path: ROUTES.DASHBOARD, component: <DashboardCrm /> },

  { path: ROUTES.PROFILE_SETTING, component: <Settings /> },
  { path: ROUTES.PROFILE, component: <UserProfile /> },

  { path: ROUTES.USER_LIST, component: <UserList /> },
  { path: ROUTES.USER_ROLE, component: <RoleList /> },
  { path: ROUTES.USER_PROFILE, component: <Profile /> },

  { path: ROUTES.AI_NEWS, component: <AINewsList /> },
  { path: ROUTES.BOOSTING_NEWS, component: <BoostingNewsList /> },

  { path: ROUTES.MY_NEWS, component: <MyNewsList /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={ROUTES.AI_NEWS} />,
  },
  { path: "*", component: <Navigate to={ROUTES.AI_NEWS} /> },
];

const publicRoutes = [
  // Authentication Page
  // {
  //   path: "/",
  //   exact: true,
  //   component: <HomeLanding />,
  // },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };

