import { createAsyncThunk } from "@reduxjs/toolkit";
import newsApi from 'api/newsApi';
import { formatQueryParams } from "helpers/format";

export const getListNews = createAsyncThunk("List News", async (params: any = {}) => {
  try {
    const response = await newsApi.aiNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
});

export const getAINewsByNormal = async (params: any = {}) => {
  try {
    const response = await newsApi.aiNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getMyNewsByNormal = async (params: any = {}) => {
  try {
    const response = await newsApi.myNews(formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};

export const getNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.news(id, params)
    return response;
  } catch (error) {
    return error;
  }
};


export const downloadNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.downloadNews(id, formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};


export const cancelMyNews = async (id: string | number, params: any = {}) => {
  try {
    const response = await newsApi.cancelMyNews(id, formatQueryParams(params))
    return response;
  } catch (error) {
    return error;
  }
};
