import { INews } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_INFO } from 'common/toast';
import BreadCrumb from 'components/Common/BreadCrumb';
import DatePickerCustom from 'components/Common/DatePickerCustom';
import DropdownStatus from 'components/Common/DropdownStatus';
import LabelWrapper from 'components/Common/LabelWrapper';
import NewsTableContainer from 'components/Common/NewsTableContainer';
import { useRole } from 'components/Hooks/UserHooks';
import FeatherIcon from "feather-icons-react";
import { COLORS_SECTION_KEYWORD, COLORS_SENTIMENT_NEWS, COLORS_STATUS_NEWS, COLOR_TYPE_SENTIMENT, SECTION_KEYWORD_OPTIONS, SENTIMENT_NEWS_OPTIONS, STATUS_NEWS_OPTIONS } from 'helpers/constans';
import { formatNumberWithCommas } from 'helpers/format';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonGroup, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, UncontrolledDropdown } from "reactstrap";
import { downloadNews, getAINewsByNormal, getNews } from 'store/thunks';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import ButtonLanguage from '../Components/ButtonLanguage';
import CopyPreventionComponent from '../Components/CopyPreventionComponent';
import CopyTextToClipboard from '../Components/CopyTextToClipboard';
import HighlightText from '../Components/HighlightText';
import NestingFilter from '../Components/NestingFilter';
import SpinnerScreen from '../Components/SpinnerScreen';

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'date';

const NewsList = () => {
  let document_w: any = window.document;
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();

  let authUser: any = localStorage.getItem("authUser") || '{}';
  const user = JSON.parse(authUser);

  const modalRef = useRef<any>(null);

  const modalBodyInnerRef = useRef<any>(null);

  const isModalFullScreenRef = useRef<number>(0);

  const isFirstLoadingPageRef = useRef<any>(true);


  const STATUS_NEWS_OPTIONS_LANG = STATUS_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const SECTION_KEYWORD_OPTIONS_LANG = SECTION_KEYWORD_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const SENTIMENT_NEWS_OPTIONS_LANG = SENTIMENT_NEWS_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const START_DATE = `${moment().subtract(7, 'days').format("Y-MM-DD")}`;
  const END_DATE = `${moment().format("Y-MM-DD")}`;

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 100),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, 'DESC'),
    start_date: withDefault(StringParam, START_DATE),
    end_date: withDefault(StringParam, END_DATE),
    keyword: withDefault(StringParam, ''),
    title: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    section: withDefault(StringParam, ''),
    sentiment_value: withDefault(StringParam, ''),
    type_page_no_param: withDefault(StringParam, ''),
  });

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [titleSearch, setTitleSearch] = useState<string>(query?.title || "");

  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.status)) || null);

  const [sentimentSearch, setSentimentSearch] = useState<Option | null>(SENTIMENT_NEWS_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.sentiment_value)) || null);

  const [sectionSearch, setSectionSearch] = useState<Option | null>(SECTION_KEYWORD_OPTIONS_LANG?.find((item) => String(item?.value) === String(query?.section)) || null);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment().toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const [newsDetail, setNewsDetail] = useState<INews | null>(null);

  const [typeShowList, setTypeShowList] = useState<'CARD' | 'TABLE'>('TABLE');

  const [typeActionUse, setTypeActionUse] = useState<'WORD' | 'PDF' | 'COPY' | null>(null);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [isCopyLoading, setIsCopyLoading] = useState<boolean>(false);

  const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);

  const [listNews, setListNews] = useState<{ list: INews[], total: number }>({ list: [], total: 0 });
  const [isListNewsLoading, setIsListNewsLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    setIsListNewsLoading((prev) => true);
    if (isFirstLoadingPageRef?.current === true) {
      isFirstLoadingPageRef.current = false;
      setQuery({
        ...query,
        type_page_no_param: '',
        end_date: moment(new Date(endDate)).format("Y-MM-DD"),
        page: 1
      });
      return;
    }
    isFirstLoadingPageRef.current = false;
    const res: any = await getAINewsByNormal(query);
    setListNews((prev: any) => ({
      list: res?.data?.list || [],
      total: res?.data?.pagination?.total || 0,
    }));
    setIsListNewsLoading((prev) => false);

    if (!!newsDetail && query?.type_page_no_param) {
      if (query?.type_page_no_param === 'prev') {
        setNewsDetail((_prev) => res?.data?.list[res?.data?.list?.length - 1]);
        return;
      }
      if (query?.type_page_no_param === 'next') {
        setNewsDetail((_prev) => res?.data?.list[0]);
        return;
      }
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
      type_page_no_param: 'next'
    });
  }

  const handleLoadPrev = () => {
    setQuery({
      ...query,
      page: query?.page - 1,
      type_page_no_param: 'prev'
    });
  }

  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query), isFirstLoadingPageRef?.current]);

  const searchData = () => {
    const queryNew = {
      ...query,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      keyword: keywordSearch || '',
      title: titleSearch || '',
      status: statusSearch?.value,
      section: sectionSearch?.value,
      sentiment_value: sentimentSearch?.value,
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      title: '',
      keyword: '',
      status: '',
      section: '',
      sentiment_value: '',
      start_date: `${moment().subtract(7, 'days').format("Y-MM-DD")}`,
      end_date: `${moment().format("Y-MM-DD")}`,
      sort_by: TYPE_SELECT_DEFAULT,
      order_by: 'DESC',
      page: 1,
      time_request: + new Date(),
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew, "push");
    setTitleSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setStatusSearch(null);
    setSectionSearch(null);
    setSentimentSearch(null);
    setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
  };

  const handleGetDetailNews = async (id_news: string | number) => {
    try {
      getNews(id_news);
    } catch (error) {
      //
    }
  };

  const handleSetNewsDetail = (detail: INews | null) => {
    setNewsDetail((_prev) => detail);
    if (!!detail) {
      handleGetDetailNews(detail?.id);
    }
    if ((window?.innerWidth <= 900)) {
      setTimeout(async () => {
        try {
          if (!!detail) {
            if (isModalFullScreenRef.current <= 0 && !(!document_w.fullscreenElement && !document_w.mozFullScreenElement && !document_w.webkitFullscreenElement)) {
              isModalFullScreenRef.current = 0;
            } else if ((!document_w.fullscreenElement && !document_w.mozFullScreenElement && !document_w.webkitFullscreenElement)) {
              isModalFullScreenRef.current = 1;
              if (document_w.documentElement.requestFullscreen) {
                document_w.documentElement.requestFullscreen();
              } else if (document_w.documentElement.mozRequestFullScreen) {
                document_w.documentElement.mozRequestFullScreen();
              } else if (document_w.documentElement.webkitRequestFullscreen) {
                document_w.documentElement.webkitRequestFullscreen();
              }
            }

          }
        } catch (error) {
          //console.error("Failed to enter fullscreen:", error);
        }
      }, 280);
    }
  }

  const onCloseClick = () => {
    handleSetNewsDetail(null);
    setCurrentLang((_prev) => 'ko');
    if (window?.innerWidth <= 900) {
      setTimeout(async () => {
        try {
          if (isModalFullScreenRef?.current > 0) {
            isModalFullScreenRef.current = 0;
            if (document_w.cancelFullScreen) {
              document_w.cancelFullScreen();
            } else if (document_w.mozCancelFullScreen) {
              document_w.mozCancelFullScreen();
            } else if (document_w.webkitCancelFullScreen) {
              document_w.webkitCancelFullScreen();
            }
          }
        } catch (error) {
          //console.error("Failed to enter fullscreen:", error);
        }
      }, 280);
    }
  }

  const scrollModalBodyToTop = () => {
    if (modalBodyInnerRef.current) {
      modalBodyInnerRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handlePreviousNews = (index_news_current: number = 0) => {
    if (listNews?.list?.length) {
      const indexNewsPrevious = Number(index_news_current) - 1;
      const newsPrevious = listNews?.list[indexNewsPrevious];
      const news2Previous = listNews?.list[indexNewsPrevious - 1];
      if (!!newsPrevious) {
        handleSetNewsDetail({
          ...(newsPrevious),
          index: indexNewsPrevious,
          disabled_prev: false,
          disabled_next: false
        });
        scrollModalBodyToTop();
        setCurrentLang((_prev) => 'ko');
      }
      if (!newsPrevious) {
        if (Number(query?.page) > 1) {
          handleLoadPrev();
        } else {
          toast(t('There is no news article anymore!'), CONFIG_OPTION_TOAST_INFO);
        }
      }
    }
  };

  const handleNextNews = (index_news_current: number = 0) => {
    if (listNews?.list?.length) {
      const indexNewsNext = Number(index_news_current) + 1;
      const newsNext = listNews?.list[indexNewsNext];
      const news2Next = listNews?.list[indexNewsNext + 1];
      if (!!newsNext) {
        handleSetNewsDetail({
          ...(newsNext),
          index: indexNewsNext,
          disabled_prev: false,
          disabled_next: false,
        });
        scrollModalBodyToTop();
        setCurrentLang((_prev) => 'ko');
      }
      if (!newsNext) {
        if (Number(query?.page) < Math.ceil(Number(listNews?.total) / Number(query?.limit || 300))) {
          handleLoadMore();
        } else {
          toast(t('There is no news article anymore!'), CONFIG_OPTION_TOAST_INFO);
        }
      }
    }
  };

  const [currentLang, setCurrentLang] = useState<string>('ko');
  const handleChangeLang = (_lang: string) => {
    const lang = newsDetail?.translates?.find((tr) => tr?.language_code === _lang);
    setNewsDetail((_prev) => (
      {
        ..._prev,
        title: lang?.title,
        body: lang?.body,
      } as INews
    ));
    setCurrentLang((_prev) => _lang);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Status'),
        accessor: "status",
        filterable: false,
        sortable: false,
        thClass: "align-middle text-center",
        thWidth: i18n?.language === 'en' ? 90 : 80,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ minWidth: '60px' }} className="text-center fs-16">
              <span className={`rounded-pill badge bg-${COLORS_STATUS_NEWS[`${cell?.value || 0}`] || 'success'}`}>
                {STATUS_NEWS_OPTIONS_LANG?.find((s) => String(s?.value) === String(item?.status))?.label || ''}
              </span>
            </div>
          );
        }
      },
      {
        Header: t('Section'),
        accessor: "section",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 130 : 90,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<div style={{ minWidth: '70px' }} className="text-center d-flex flex-column justify-content-center align-items-center gap-1 fs-16">
            <span key={item?.section} className={`badge bg-${COLORS_SECTION_KEYWORD[`${item?.section || ''}`] || 'success'}-subtle text-${COLORS_SECTION_KEYWORD[`${item?.section || ''}`] || 'success'}`}>
              {SECTION_KEYWORD_OPTIONS_LANG?.find((s) => String(s?.value) === String(item?.section))?.label || ''}
            </span>
          </div>)
        },
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        thWidth: 100,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '80px' }} className="fs-15">{cell?.value || ''}</div>
          </>)
        },
      },
      {
        Header: t('News Title'),
        accessor: "title",
        filterable: true,
        sortable: false,
        // thWidth: 240,
        thClass: 'align-middle',
        thWidth: !listNews?.list?.length ? 100 : (window.innerWidth < 1650 ? 270 : 600),
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ minWidth: '250px', maxWidth: '600px' }} className={`fs-15 cursor-pointer hover-underline`} onClick={(e) => {
              e.preventDefault();
              if (!item?.status || (String(item?.user_id) === String(user?.id))) {
                handleSetNewsDetail({ ...item, index: cell?.row?.index });
              } else {
                toast(t('Already used article!'), CONFIG_OPTION_TOAST_INFO);
              }
            }}>
              {(String(item?.user_id) === String(user?.id)) && <i className="ri-download-line text-primary me-0" />} {cell?.value || ''}
            </div>
          </>)
        },
      },
      {
        Header: t('Monthly Search Volume'),
        accessor: "search_volume",
        filterable: true,
        sortable: true,
        thWidth: i18n?.language === 'en' ? 200 : 140,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: i18n?.language === 'en' ? '180px' : '120px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t('Competing Articles'),
        accessor: "news_count",
        filterable: true,
        sortable: false,
        thWidth: i18n?.language === 'en' ? 160 : 120,
        thClass: 'align-middle text-center',
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="text-center" style={{ minWidth: i18n?.language === 'en' ? '150px' : '100px' }}>{formatNumberWithCommas(cell?.value || 0)}</div>
          </>)
        },
      },
      {
        Header: t("Sentiment"),
        thClass: 'align-middle text-center align-top',
        filterable: false,
        sortable: false,
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="text-center">
              <div style={{ minWidth: "100px" }} className={`text-center text-${COLOR_TYPE_SENTIMENT?.[`${item?.sentiment?.sentiment_value || '-'}`] || 'muted'}`}>
                {t(`${item?.sentiment?.sentiment_value || '-'}`)}
              </div>
            </div>
          );
        },
      },
      {
        Header: t('Created At'),
        accessor: "date",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: 'align-middle text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end pe-3" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span></span>
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: "__",
        thClass: 'align-middle text-center',
        thWidth: 100,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0" style={{ minWidth: '90px' }}>
              {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) &&
                <li className=" list-inline-item me-0" id={`detail-us-${item?.id}`}>
                  <Link className={`btn btn-sm ${(!item?.status || (String(item?.user_id) === String(user?.id))) ? 'btn-soft-primary' : 'bg-secondary-subtle text-secondary'} edit-item-btn`} to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!item?.status || (String(item?.user_id) === String(user?.id))) {
                        handleSetNewsDetail({ ...item, index: cell?.row?.index });
                      } else {
                        toast(t('Already used article!'), CONFIG_OPTION_TOAST_INFO);
                      }
                    }}
                  >
                    {t('Button Get News')}
                  </Link>
                </li>}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, listNews?.list?.length, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setListNews((prev: any) => ({
      list: [],
      total: 0,
    }));
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy, page: 1 });
    });
  }, []);

  const handleCallAllOption = async () => {
    try {
      // const [resRole]: any = await Promise.all([getAllRoles()]);
      // if (resRole?.data) {
      //   setListRole((_prev) => resRole?.data);
      // }
    } catch (error: any) {
      return error;
    }
  };

  async function downloadFileFromUrl(fileUrl: string, fileName: string) {
    try {
      // Send a file download request using Fetch API
      const response = await fetch(fileUrl);

      // Check if the response is unsuccessful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the file content as a blob
      const blob = await response.blob();

      // Create a blob URL
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a link to download the file
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName; // Set the downloaded file name
      document.body.appendChild(link);

      // Trigger the file download
      link.click();

      // Revoke the blob URL and remove the link element
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download failed:", error);
    }
  }

  const copyTextToClipboard = useCallback(async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    toast(`${t('The news has been copied to the clipboard. The title and body copy feature has been activated.')}`, CONFIG_OPTION_TOAST_INFO);
  }, []);

  const handleCopyNews = async (id_news: string | number = '') => {
    try {
      if (!id_news) {
        return;
      }
      onCloseConfirmClick();
      setIsCopyLoading((_prev) => true);
      const response: any = await downloadNews(id_news, { type: 'COPY', language_code: currentLang });
      setIsCopyLoading((_prev) => false);
      if (response?.data) {
        setListNews((_prev) => (
          {
            list: _prev?.list?.map((item) => (item?.id === id_news ? { ...item, status: 1, is_can_copy: true, user_id: user?.id } : item)),
            total: _prev?.total,
          }
        ));
        setNewsDetail((_prev) => ({ ..._prev, status: 1, is_can_copy: true, user_id: user?.id }) as INews);
        copyTextToClipboard(`${newsDetail?.title}\n\n${newsDetail?.body}`);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsCopyLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleDownloadNews = async (id_news: string | number = '', type_file: 'WORD' | 'PDF' = 'WORD') => {
    try {
      if (!id_news) {
        return;
      }
      onCloseConfirmClick();
      setIsDownloading((_prev) => true);
      const response: any = await downloadNews(id_news, { type: type_file, language_code: currentLang });
      if (response?.data) {
        setIsDownloading((_prev) => false);
        // Get file name from URL or set default name
        const urlParts = response?.data?.url.split('/');
        const fileName = urlParts[urlParts.length - 1] || 'AI_News.pdf';
        downloadFileFromUrl(response?.data?.url, fileName);
        setListNews((_prev) => (
          {
            list: _prev?.list?.map((item) => (item?.id === id_news ? { ...item, status: 1, user_id: user?.id } : item)),
            total: _prev?.total,
          }
        ));
        setNewsDetail((_prev) => ({ ..._prev, status: 1, user_id: user?.id }) as INews);
      } else {
        setIsDownloading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsDownloading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const handleConfirmPhotoNews = () => {
    setIsOpenPhoto(true);
  };

  const handleOpenTabPhotoNews = () => {
    setIsOpenPhoto(false);
    window.open(`https://www.google.com/search?q=${newsDetail?.keyword}&sclient=img&udm=2`, '_blank');
  };

  const handleActionUse = () => {
    if (!typeActionUse) { return };
    if (['WORD', 'PDF']?.includes(typeActionUse)) {
      handleDownloadNews(newsDetail?.id, typeActionUse as 'WORD' | 'PDF');
      return;
    }
    if (['COPY']?.includes(typeActionUse)) {
      handleCopyNews(newsDetail?.id);
      return;
    }
  };

  const onCloseConfirmClick = () => {
    setTypeActionUse((_prev) => null);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handelChangeSection = (section_search: Option) => {
    const queryNew = {
      ...query,
      section: section_search?.value,
      page: 1,
      time_request: + new Date()
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setListNews((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
    setSectionSearch(section_search)
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('AI News')} | ${t('PRESS Ai')}`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const [isS, setIsS] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(async () => {
      try {
        setIsS((_prev) => false);
      } catch (error) {
        //console.error("Failed to enter fullscreen:", error);
      }
    }, 2600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('AI News')} pageTitle={t('Home')} />
          <Row className="h-auto-custom">
            {isHavePermissionRole(ROLES_FOR_APP.AI_NEWS, userPermissions) && (
              <Col lg={12}>
                <SpinnerScreen isShow={isS} />
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-6">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            {(isListNewsLoading && query?.page === 1) ? <Spinner size="sm" className="text-primary"></Spinner> : (<CountUp
                              start={0}
                              end={listNews?.total || 0}
                              duration={1}
                              className="text-primary"
                            />)}
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 text-end">
                        <button
                          type="button"
                          className="btn btn-info py-1 px-2"
                          onClick={() => setTypeShowList((_prev) => (_prev === 'CARD' ? 'TABLE' : 'CARD'))}
                        >
                          {typeShowList === 'TABLE' ? <i className="ri-list-check-2 align-center fs-20" /> : <i className="ri-table-2 align-center fs-20" />}
                        </button>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4 px-0 px-lg-3 pt-0 pt-lg-3">
                    <NestingFilter>
                      <Row className="g-4 align-items-end mt-0">
                        <Col sm={6} md={6} lg={6} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Keyword')} isShow={!!keywordSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('Keyword')}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={4} xxl={3} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('News Title')} isShow={!!titleSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t('News Title')}...`}
                              value={titleSearch}
                              onChange={(e) => setTitleSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Status')} isShow={true}>
                            <DropdownStatus
                              name="status"
                              dataList={STATUS_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Status")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={statusSearch || null}
                              onChangeSelect={(e: any) => setStatusSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_STATUS_NEWS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4} xxl={2} className='mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Sentiment')} isShow={true}>
                            <DropdownStatus
                              name="sentiment"
                              dataList={SENTIMENT_NEWS_OPTIONS_LANG || []}
                              placeholder={`${t("Sentiment")}...`}
                              className="dropdown-status-rounded z-hight"
                              classNamePrefix="name-prefix"
                              initialValue={sentimentSearch || null}
                              onChangeSelect={(e: any) => setSentimentSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t('All'), value: '' }}
                              colors={COLORS_SENTIMENT_NEWS}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={4} xxl={3} className='date-picker-wrapper-custom mt-3 mt-xxl-2'>
                          <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                            <DatePickerCustom
                              placeholder={`${t('Start Date')} - ${t('End Date')}`}
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                              showOptions={[
                                'today', 'yesterday', 'two_day_ago',
                                'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                'this_week', 'last_week',
                                'this_month', 'last_month',
                              ]}
                            />
                          </LabelWrapper>
                        </Col>
                        <Col sm={6} md={6} lg={12} xl={4} xxl={12} className="hstack gap-1 justify-content-center justify-content-sm-start justify-content-lg-end justify-content-xl-start justify-content-xxl-end mt-3 mt-xxl-3">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-2 me-lg-2"
                              onClick={searchData}
                              disabled={isListNewsLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </NestingFilter>
                    <Col sm={12} md={12} xl={12} xxl={12} className="mt-4 px-3 px-lg-0">
                      <div>
                        <div className="d-flex gap-2 flex-wrap">
                          <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.section) === '' ? 'bg-primary text-white' : 'border-gray text-secondary'}`}
                            onClick={(e) => { e.preventDefault(); handelChangeSection({ label: t('All'), value: '' }) }}
                          >
                            {t('All')}
                          </button>
                          {SECTION_KEYWORD_OPTIONS_LANG?.map((item) => (
                            <button type="button" className={`btn px-3 px-md-4 py-btn-section ${String(query?.section) === String(item?.value) ? `bg-primary text-white` : `border-gray text-secondary`}`} key={item?.value}
                              onClick={(e) => { e.preventDefault(); handelChangeSection(item) }}
                            >
                              {item?.label}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </div>
                  <CardBody className="pt-0 px-0">
                    {!isS &&
                      <div className="mx-3 my-4 mt-3">
                        <NewsTableContainer
                          className="custom-header-css"
                          divClass="table-responsive table-card"
                          tableClass="align-middle sticky-table-ai-news"
                          theadClass="table-light"
                          columns={columns}
                          data={listNews?.list?.length ? listNews?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={listNews?.total}
                          customPageCount={Math.ceil(Number(listNews?.total) / Number(query?.limit || 300))}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isListNewsLoading}
                          typeShowList={typeShowList}
                          handleClickDetailCard={handleSetNewsDetail}
                          isShowPagination={true}
                          isShowLoadingBottom={false}
                          isScrollToTop={true}
                        />
                      </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <ToastContainer closeButton={false} newestOnTop={false} pauseOnFocusLoss autoClose={2000} limit={2} />
        <Modal
          isOpen={!!newsDetail}
          centered={window?.innerWidth > 900 ? true : false}
          size="xl"
          className={`border-header-footer ${window?.innerWidth > 900 ? '' : 'modal-fullscreen '}`}
          scrollable={window?.innerWidth > 900 ? true : false}
          toggle={onCloseClick}
          keyboard={true}
          innerRef={modalRef}
        >
          <ModalHeader toggle={onCloseClick}>
            <div className="d-flex flex-column flex-sm-row gap-0 gap-sm-2 fs-18 fs-md-20">
              <div className="flex-grow-1">
                {t('AI News')}: <span style={{ backgroundColor: '#ffff00' }}>{newsDetail?.keyword || ''}</span>
                <ButtonLanguage currentLang={currentLang} handleChangeLang={handleChangeLang} />
                <span className={`mx-2 rounded-pill badge bg-${COLORS_STATUS_NEWS[`${newsDetail?.status || 0}`] || 'success'}`}>
                  {STATUS_NEWS_OPTIONS_LANG?.find((s) => String(s?.value) === String(newsDetail?.status))?.label || ''}
                </span>
                <span key={newsDetail?.section} className={`rounded-pill me-2 badge bg-${COLORS_SECTION_KEYWORD[`${newsDetail?.section || ''}`] || 'success'}-subtle text-${COLORS_SECTION_KEYWORD[`${newsDetail?.section || ''}`] || 'success'}`}>
                  {SECTION_KEYWORD_OPTIONS_LANG?.find((s) => String(s?.value) === String(newsDetail?.section))?.label || ''}
                </span>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="position-relative">
            <div ref={modalBodyInnerRef} className="inner-modal-body color-scrollbar-os">
              <CopyPreventionComponent isCopy={!!newsDetail?.is_can_copy}>
                <div className="pb-3">
                  <p className="mb-3 fs-22 fs-md-23" style={{ lineHeight: '170%', fontWeight: 600 }}>
                    <CopyTextToClipboard text={newsDetail?.title || ''} isCopy={!!newsDetail?.is_can_copy}>
                      <HighlightText text={newsDetail?.title || ''} keyword={newsDetail?.keyword || ''} />
                    </CopyTextToClipboard>
                  </p>
                  <p className="mb-3 mt-3 fs-17 fs-md-18" style={{ whiteSpace: 'pre-line', lineHeight: '170%' }}>
                    <CopyTextToClipboard text={newsDetail?.body || ''} isCopy={!!newsDetail?.is_can_copy}>
                      <HighlightText text={newsDetail?.body || ''} keyword={newsDetail?.keyword || ''} />
                    </CopyTextToClipboard>
                  </p>
                </div>
              </CopyPreventionComponent>
            </div>
          </ModalBody>
          <ModalFooter className="position-relative footer-button-new" lang={i18n?.language || 'en'}>
            <div className="w-100 d-flex gap-2 justify-content-between align-items-center flex-wrap ">
              <strong>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 fs-14 fs-md-16">
                    {t('The count of keyword occurrences in the news')}:
                  </div>
                  <div className="flex-grow-1 fs-14 fs-md-16 ms-1">
                    <span className="fw-medium">{Number(newsDetail?.title_count || 0) + Number(newsDetail?.body_count || 0)}</span>
                  </div>
                </div>
              </strong>
              <div className="d-flex flex-grow-1 gap-2 gap-sm-3 justify-content-end justify-content-sm-end mt-2 mt-sm-0 flex-wrap-sm-300">
                <div className="btn-group btn-width-next-news">
                  <button type="button" title={t('Previous News')} style={{ height: '40px' }} aria-pressed="false"
                    className="btn fs-14 btn-secondary d-flex justify-content-center align-item-center"
                    disabled={isListNewsLoading || isDownloading || !!newsDetail?.disabled_prev}
                    onClick={() => handlePreviousNews(newsDetail?.index)}
                  >
                    {(isListNewsLoading && query?.type_page_no_param === 'prev') ? <span style={{ transform: 'translateY(3px)', width: '18px' }}><Spinner size="sm" ></Spinner></span> : <FeatherIcon style={{ width: '18px' }} icon="chevron-left" className="fs-14" role="img" />}
                  </button>
                  <button type="button" title={t('Next News')} style={{ height: '40px' }} aria-pressed="false"
                    className="btn fs-14 btn-secondary d-flex justify-content-center align-item-center"
                    disabled={isListNewsLoading || isDownloading || !!newsDetail?.disabled_next}
                    onClick={() => handleNextNews(newsDetail?.index)}
                  >
                    {(isListNewsLoading && query?.type_page_no_param === 'next') ? <span style={{ transform: 'translateY(3px)', width: '18px' }}><Spinner size="sm" ></Spinner></span> : <FeatherIcon style={{ width: '18px' }} icon="chevron-right" className="fs-14" role="img" />}
                  </button>
                </div>
                <button
                  className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center btn-width-photo-news"
                  type="button"
                  color="light"
                  onClick={() => handleConfirmPhotoNews()}
                  disabled={isDownloading || isCopyLoading || (!!newsDetail?.status && (String(newsDetail?.user_id) !== String(user?.id)))}
                >
                  <i className="ri ri-image-line" style={{ lineHeight: '22px' }} /><span className="ms-1">{t("Photo")}</span>
                </button>
                <button
                  className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center btn-width-copy-news"
                  type="button"
                  color="light"
                  onClick={() => !!newsDetail?.status ? handleCopyNews(newsDetail?.id) : setTypeActionUse('COPY')}
                  disabled={isDownloading || isCopyLoading || (!!newsDetail?.status && (String(newsDetail?.user_id) !== String(user?.id)))}
                >
                  {isCopyLoading ? <span><Spinner size="sm" ></Spinner></span> : <i className="ri ri-file-copy-line" style={{ lineHeight: '22px' }} />} <span className="ms-1">{t("Copy")}</span>
                </button>
                <ButtonGroup>
                  <UncontrolledDropdown>
                    <DropdownToggle tag="button" className="btn btn-primary fs-14 px-1 d-flex justify-content-center align-item-center btn-width-down-news" disabled={isDownloading || isCopyLoading || (!!newsDetail?.status && (String(newsDetail?.user_id) !== String(user?.id)))}>
                      {isDownloading ? <span><Spinner size="sm" ></Spinner></span> : <i className="ri-download-line" />} <span className="ms-1">{t("Button Use News")}</span> <i className=" ms-1 mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => !!newsDetail?.status ? handleDownloadNews(newsDetail?.id, 'WORD') : setTypeActionUse('WORD')}>{t('File Word')}</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={() => !!newsDetail?.status ? handleDownloadNews(newsDetail?.id, 'PDF') : setTypeActionUse('PDF')}>{t('File PDF')}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonGroup>
                <button
                  className="btn btn-secondary fs-14 px-1 d-flex justify-content-center align-item-center btn-width-close-news"
                  type="button"
                  color="light"
                  onClick={onCloseClick}
                  disabled={isDownloading}
                >
                  <i className="ri-close-fill" style={{ lineHeight: '22px' }} /> <span className="ms-1">{t("Button Close")}</span>
                </button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={!!typeActionUse}
          centered={true}
          size="sm"
          toggle={onCloseConfirmClick}
          keyboard={true}
        >
          <ModalHeader>
            {t('Confirm')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <div className="mt-2 text-center">
                    <h5 className="mb-4">
                      {t("Points will be deducted")}
                    </h5>
                    <div className="d-flex gap-3 justify-content-center">
                      <button
                        className={`btn rounded-pill btn-soft-primary fs-14`}
                        type="button"
                        onClick={handleActionUse}
                        style={{ minWidth: '100px' }}
                      >

                        {t("Button OK")}
                      </button>
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={onCloseConfirmClick}
                        style={{ minWidth: '100px' }}
                      >
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >

        <Modal
          isOpen={!!isOpenPhoto}
          centered={true}
          // size="sm"
          toggle={() => setIsOpenPhoto(false)}
          keyboard={true}
        >
          <ModalHeader>
            {t('Photo')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col sm={12}>
                <div className="row g-3">
                  <div className="mt-3 text-center">
                    <h6 className="mb-2 fs-19 text-center">
                      {t("Would you like to proceed to Google Images?")}
                    </h6>
                    <p className="fs-15 mb-4 d-flex justify-content-center align-items-start align-items-sm-center text-center "> <i className="ri-error-warning-line me-1 fs-18" /> {t("The responsibility for photo copyrights lies with you.")}</p>
                    <div className="d-flex gap-3 mt-2 justify-content-center">
                      <button
                        className={`btn rounded-pill btn-soft-primary fs-14`}
                        type="button"
                        onClick={handleOpenTabPhotoNews}
                        style={{ minWidth: '100px' }}
                      >
                        {t("Button Move")}
                      </button>
                      <button
                        className="btn btn-soft-secondary rounded-pill fs-14"
                        type="button"
                        color="light"
                        onClick={() => setIsOpenPhoto(false)}
                        style={{ minWidth: '100px' }}
                      >
                        {t("Button Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >

      </div >
    </React.Fragment >
  );
};

export default NewsList;