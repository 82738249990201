import React from 'react';

interface HighlightTextProps {
    text: string;
    keyword: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, keyword }) => {
    if (!keyword) return <span>{text}</span>;

    const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

    return (
        <span>
            {parts.map((part, index) => (
                part.toLowerCase() === keyword.toLowerCase() ? (
                    <span key={index} style={{ backgroundColor: '#ffff00' }}>
                        {part}
                    </span>
                ) : (
                    part
                )
            ))}
        </span>
    );
};

export default HighlightText;
