interface ApiConfig {
  API_URL: string;
}

interface Config {
  api: ApiConfig;
}


const config: Config = {
  api: {
    API_URL: `${process.env.REACT_APP_API_URL}`,
  },
};

export default config;