import { createSlice } from "@reduxjs/toolkit";
import { INews } from "api/types/_news";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getListNews } from "./thunk";


export interface IState {
  news: ResponseData<INews[]> & PaginationResponse | null,
  isNewsLoading: boolean,
  isNewsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  news: null,
  isNewsLoading: false,
  isNewsSuccess: false,

  error: {},
};

const NewsSlice = createSlice({
  name: "News",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get News
    builder.addCase(getListNews.pending, (state: IState, action: any) => {
      state.isNewsLoading = true
    });
    builder.addCase(getListNews.fulfilled, (state: IState, action: any) => {
      state.news = action.payload.data;
      state.isNewsSuccess = true;
      state.isNewsLoading = false;
    });
    builder.addCase(getListNews.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsSuccess = false;
      state.isNewsLoading = false;
    });
  },
});

export default NewsSlice.reducer;