import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { INews } from "./types/_news";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/news';
const newsApi = {
  aiNews(params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  myNews(params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/my-news`
    return api.get(url, params)
  },
  news(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<INews>>> {
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  downloadNews(id: string | number, params: any = {}): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/download`;
    const TYPE_DOWNLOAD: any = {
      COPY: 1,
      WORD: 2,
      PDF: 3
    };
    return api.post(url, {
      news_id: id,
      download_type: TYPE_DOWNLOAD?.[`${params?.type}`] || '',
      language_code: params?.language_code || 'ko',
    })
  },
  cancelMyNews(id: string | number, params: any): Promise<AxiosResponse<ResponseData<INews[]> & PaginationResponse, any>> {
    const url = `${path}/${id}/cancel-used`
    return api.put(url, params)
  },
}
export default newsApi
