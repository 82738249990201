import { createSlice } from "@reduxjs/toolkit";
import { ICategory } from "api/types/_category";
import { ResponseData } from "api/types/_public";


export interface IState {

  allCategory: ResponseData<ICategory[]> | null,

  error: any,
};

export const initialState: IState = {

  allCategory: null,

  error: {},
};

const CategorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Categories

  },
});

export default CategorySlice.reducer;