import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import flagchina from "../../../assets/images/flags/cn.svg";
import flagjapan from "../../../assets/images/flags/jp.svg";
import flagkorea from "../../../assets/images/flags/kr.svg";
import flagus from "../../../assets/images/flags/us.svg";

interface ButtonLanguageProps {
    currentLang?: string,
    handleChangeLang: (lang: string) => void;
}

const FLAGS: any = {
    'ko': flagkorea,
    'en': flagus,
    'ja': flagjapan,
    'cn-zh': flagchina
};

const ButtonLanguage: React.FC<ButtonLanguageProps> = ({ currentLang = 'ko', handleChangeLang }) => {
    const { t, i18n } = useTranslation();
    const handelButtonLanguage = (lang: string) => {
        handleChangeLang && handleChangeLang(lang);
    };

    return (
        <ButtonGroup>
            <UncontrolledDropdown>
                <DropdownToggle tag="button" className="btn btn-light btn-sm fs-14 px-2 ms-3  d-flex justify-content-center align-item-center btn-width-lang-news">
                    <img
                        src={FLAGS[currentLang] || FLAGS['ko']}
                        alt="Header Language"
                        height="20"
                        className="rounded"
                    />
                    <span className="ms-1">
                        {t(currentLang)}
                    </span> <i className=" ms-1 mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => handelButtonLanguage('ko')}>
                        <div className="d-flex align-items-center">
                            <img
                                src={FLAGS['ko']}
                                alt="Header Language"
                                height="20"
                                className="rounded me-2"
                            />
                            {t('ko')}
                        </div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => handelButtonLanguage('en')}>
                        <div className="d-flex align-items-center" >
                            <img
                                src={FLAGS['en']}
                                alt="Header Language"
                                height="20"
                                className="rounded me-2"
                            />{t('en')}
                        </div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => handelButtonLanguage('cn-zh')}>
                        <div className="d-flex align-items-center">
                            <img
                                src={FLAGS['cn-zh']}
                                alt="Header Language"
                                height="20"
                                className="rounded me-2"
                            />{t('cn-zh')}
                        </div>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => handelButtonLanguage('ja')}>
                        <div className="d-flex align-items-center">
                            <img
                                src={FLAGS['ja']}
                                alt="Header Language"
                                height="20"
                                className="rounded me-2"
                            />{t('ja')}
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </ButtonGroup>
    );
};

export default React.memo(ButtonLanguage);
