import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ICategory } from "./types/_category";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/categories';
const categoryApi = {
  allCategory(params: any = {}): Promise<AxiosResponse<ResponseData<ICategory[]>, any>> {
    const url = `masters/categories`
    return api.get(url, params)
  },
}
export default categoryApi
