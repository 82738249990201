import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionItem, Collapse } from "reactstrap";

interface NestingFilterProps {
    children: React.ReactNode;
}

const NestingFilter: React.FC<NestingFilterProps> = ({ children }) => {
    const { t, i18n } = useTranslation();
    // Nesting
    const [nestingFilter, setNestingFilter] = useState<boolean>(window?.innerWidth > 960);

    const handelNestingFilter = () => {
        setNestingFilter(!nestingFilter);
    };

    return (
        <Accordion open={'collapseFilter'} className=" accordion-border-box accordion-border-box-mobile">
            <AccordionItem className="border-0">
                <h2 className="accordion-header d-block d-lg-none" id="headingThree">
                    <button
                        className={`accordion-button rounded-0 px-3 ${!nestingFilter ? 'collapsed' : ''}`} type="button" onClick={handelNestingFilter} style={{ cursor: "pointer" }} >
                        <i className="ri ri-filter-3-line me-2 fs-17" /> {t('Filter')}
                    </button>
                </h2>
                <Collapse isOpen={(window?.innerWidth > 960) ? true : nestingFilter} className="accordion-collapse" id="collapseFilter">
                    <div className="accordion-body p-3 p-lg-0">
                        {children}
                    </div>
                </Collapse>
            </AccordionItem>
        </Accordion>
    );
};

export default React.memo(NestingFilter);
