import { IPermission, IRole } from 'api/types/_role';
import LabelWrapper from 'components/Common/LabelWrapper';
import TooltipCustom from 'components/Common/TooltipCustom';
import { useRole } from 'components/Hooks/UserHooks';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ROLES_FOR_APP, isHavePermissionRole } from 'helpers/role';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from 'react-countup';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllPermissions, getRoles as onGetRoles } from "../../../store/thunks";
import RoleForm from './RoleForm';


interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const RoleList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    name: withDefault(StringParam, ''),
  });
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [roleEdit, setRoleEdit] = useState<IRole & { isCopy?: boolean } | null>(null);
  const [permissions, setPermissions] = useState<IPermission[]>([]);


  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Role;

  const RoleProperties = createSelector(
    selectLayoutState,
    (state) => ({
      roles: state.roles,
      isRoleSuccess: state.isRoleSuccess,
      isRoleLoading: state.isRoleLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { roles, error, isRoleLoading } = useSelector(RoleProperties);

  useEffect(() => {
    dispatch(onGetRoles(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: + new Date()
    });
  };

  const resetData = () => {
    setQuery({
      name: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      time_request: + new Date(),
      page: 1,
    }, "push")
    setKeywordSearch((_prev) => "");
    setMediasSearch([]);
  };

  // Begin::Update Role
  const onCloseClick = () => {
    setRoleEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetRoles(query));
  }
  //End::Update Role

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '100px' }}>{cell?.value}</div>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <div style={{ minWidth: '100px' }}>
            {cell?.value}
          </div>
        ),
      },
      {
        Header: t('Description'),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span style={{ minWidth: '100px' }}>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        thWidth: 150,
        thClass: 'text-end',
        Cell: (cell: any) => {
          const arrDate = String(cell?.value || '').split(' ');
          return (
            <div className="text-end" style={{ minWidth: '100px' }}>
              <span><span>{arrDate?.[0] || ''}</span> <br /> <span className="text-secondary">{arrDate?.[1] || ''}</span> </span>
            </div>
          )
        },
      },
      {
        Header: t('Action'),
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              {isHavePermissionRole(ROLES_FOR_APP.ROLE_UPDATE, userPermissions) && <TooltipCustom
                title={t('Button Update Role')}
                id={`update-rol-${item?.id}`}
              >
                <li className=" list-inline-item me-0" id={`update-rol-${item?.id}`} >
                  <Link className="btn btn-sm btn-soft-primary edit-item-btn" to="#"
                    onClick={(e) => { e.preventDefault(); setRoleEdit((prev) => item) }}
                  >
                    <i className="ri-pencil-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query, userPermissions]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);



  const handleCallAllOption = async () => {
    try {
      const [resPermissions]: any = await Promise.all([getAllPermissions()]);
      if (resPermissions?.data) {
        setPermissions((_prev) => resPermissions?.data);
      }
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t('Role')} - ${t('Role')} | AdsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Role')} pageTitle={t('Role')} />
          <Row>
            {isHavePermissionRole(ROLES_FOR_APP.ROLE_LIST, userPermissions) && (
              <Col lg={12}>
                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t('Total')}:</span>
                            <CountUp
                              start={0}
                              end={roles?.pagination?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                      {isHavePermissionRole(ROLES_FOR_APP.ROLE_CREATE, userPermissions) && (<div className="col-sm-auto">
                        <div>
                          <button
                            type="button"
                            className="btn btn-success add-btn rounded-pill"
                            id="create-btn"
                            onClick={() => setIsOpen((_prev) => true)}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {t('Button Create Role')}
                          </button>
                        </div>
                      </div>)}
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={6} md={6} xl={3} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Name')} isShow={!!keywordSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Name')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>
                      <Col sm={12} md={12} xl={6} xxl={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-3 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-2 "
                            onClick={searchData}
                            disabled={isRoleLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-3">
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={roles?.list?.length ? roles?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={roles?.pagination?.total}
                      customPageCount={Math.ceil(Number(roles?.pagination?.total) / Number(roles?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isRoleLoading}
                    />
                    <ToastContainer closeButton={false} autoClose={1000} limit={2} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={!!roleEdit?.id || isOpen}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(roleEdit?.id) ? t("Button Update Role") : t("Button Create Role")}</>  <>{(roleEdit?.id) && (' - ' + (roleEdit?.name || '') || '')}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <RoleForm
                isModal={true}
                permissions={permissions || []}
                id={String(roleEdit?.id || '')}
                isCopy={!!(roleEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
                onCloseClick={onCloseClick}
              />
            </div>
          </ModalBody>
        </Modal>
      </div >
    </React.Fragment >
  );
};

export default RoleList;