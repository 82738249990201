import categoryApi from 'api/categoryApi';

export const getAllCategory = async (params: any = {}) => {
  try {
    const response = await categoryApi.allCategory(params)
    return response;
  } catch (error) {
    return error;
  }
};

